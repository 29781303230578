<template>
  <v-card :loading="loading">
      <v-card-title class="text-h4 font-weight-bold">สร้างแบบฟอร์ม<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="text-left">
         <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense class="ma-0 pa-0">
              <v-col cols="12">
                  <TextField :title="'ชื่อแบบฟอร์ม'" :required="true" v-bind:value="value.name" @input="update('name',$event)" :rules="formNameRules"/>
              </v-col>
              <v-col cols="6">
                   <TextField :title="'ประเภท'" readonly :value="value.formTypeName" />
                  <!-- <Select :title="'ประเภท'" :rules="typeRules" :required="true" :placeholder="'เลือกประเภท'" :items="items" return-object v-model="type" item-text="formTypeName"/> -->
              </v-col>
                <v-col cols="6">
                  <Select :title="'ประจำปี'" :items="yearItems" :rules="yearRules"  item-text="yearTH" item-value="yearEN" :required="true" v-bind:value="value.year" @input="update('year',$event)"/>
              </v-col>
          </v-row>
             </v-form>
      </v-card-text>
      <v-card-actions>
     <v-row class="pr-3 pl-3">
              <v-col cols="6" class="pa-2"><v-btn block outlined color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn></v-col>
              <v-col cols="6" class="pa-2"><v-btn block color="primary" large @click="onSubmit()"><span class="text-title-2">{{action}}</span></v-btn></v-col>
          </v-row>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import TextField from '@/components/common/TextField'
import Select from '@/components/common/Select'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean
    },
    formTypeItems: {
      type: Array
    },
    yearItems: {
      type: Array
    },
    value: {
      type: Object
    }
  },
  components: {
    TextField,
    Select
  },
  computed: {
    items () {
      return this.formTypeItems.filter(data => data.formTypeId !== '')
    }
  },
  data () {
    return {
      name: this.value.name,
      type: this.value.formTypeId,
      loading: false,
      valid: false,
      year: this.value.year,
      formNameRules: [
        v => !!v || 'กรุณาระบุชื่อ'
      ],
      typeRules: [v => !!v || 'กรุณาเลือกประเภท'],
      yearRules: [v => !!v || 'กรุณาเลือกประเภท']
    }
  },
  watch: {
    status (val) {
      if (val) {
        this.type = null
        this.$refs.form.reset()
      }
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        // this.$emit('change', { ...this.type, name: this.name, year: this.year, description: '' })
        this.$emit('onSubmit')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>
